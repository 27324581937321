import {
  Button,
  Checkbox,
  TextField,
  Typography,
  Link,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  ChangeEvent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Navigate, useOutletContext, useSearchParams } from "react-router-dom";
import { object, string, boolean, ValidationError } from "yup";
import { Profanity, ProfanityOptions } from "@2toad/profanity";
import { SectionHero } from "../ui/components/SectionHero";
import { AuthContext } from "./AuthProvider";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ErrorToast } from "./ErrorToast";
import { userIsUnlocked } from "./utils";
import { OutletContextParams } from "./Signup";

const accountSchema = object({
  username: string().required(),
  email: string().email("Please input a valid email.").required(),
  password: string().min(8).required("Password is required"),
  terms: boolean().oneOf(
    [true],
    "Please indicate that you accept the Terms & Conditions"
  ),
});

const options = new ProfanityOptions();
options.wholeWord = false;

const profanity = new Profanity(options);

export interface AccountFields {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
}

interface Action {
  key: string;
  value: string | boolean;
}

export function AccountForm() {
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [migratingFromEmailCTA, setMigratingFromEmailCTA] = useState(false);

  const [searchParams] = useSearchParams();

  const reducer = (state: AccountFields, action: Action) => ({
    ...state,
    [action.key]: action.value,
  });

  const { paid, recovery } = useOutletContext<OutletContextParams>();

  const { signUp, user } = useContext(AuthContext);

  const [state, dispatch] = useReducer(reducer, {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
  });

  useEffect(() => {
    const param = searchParams.entries().next();

    if (param.value && param.value.length > 0 && param.value[0] === "email") {
      console.log(`${param.value[0]}: ${param.value[1]}`);
      dispatch({ key: "email", value: param.value[1] });
      setMigratingFromEmailCTA(true);
    }
  }, [searchParams, dispatch]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const handleInput =
    (key: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch({ key, value: e.target.value });
    };

  const handleTermsCheck = () =>
    dispatch({ key: "terms", value: !state.terms });

  const handleSubmit = async () => {
    try {
      await accountSchema.validate(state, {
        abortEarly: false,
      });

      if (state.password !== state.confirmPassword) {
        throw new ValidationError(
          "Passwords must match",
          "Passwords must match",
          "passwordConfirmation"
        );
      }

      if (profanity.exists(state.username)) {
        throw new ValidationError(
          "Username contains prohibited language",
          "Username contains prohibited language",
          "username"
        );
      }

      const success = await signUp(state);

      setSuccess(success);
    } catch (err) {
      const errors: Record<string, string[]> = {};
      if (err instanceof ValidationError) {
        console.log("validation error");
        console.log(err);
        if (err.inner?.length > 0) {
          err.inner.forEach((e: ValidationError) => {
            if (errors[e.path!]?.length > 0) {
              console.log(e.message);
              errors[e.path!].push(e.message);
            } else {
              console.log(e.message);
              errors[e.path!] = [e.message];
            }
          });
        } else {
          errors[err.path!] = [err.message];
        }
        setErrors(errors);
      } else {
        console.log(err);
      }
    }
  };

  return (
    <div id="account-info-update-page" className="flex flex-col lg:items-center">
      {user && userIsUnlocked(user) && <Navigate to="/" />}
      {user && !userIsUnlocked(user) && <Navigate to="/subscribe" replace />}
      {success && <Navigate to="../confirm" state={{ paid, recovery }} />}
      <SectionHero title="Account Setup" />
      <div className="lg:max-w-[750px]">
        <ErrorToast />
        <div className="mt-[20px] flex flex-col gap-1 px-6">
          <Typography variant="h6" className="mb-4">
            Let's start with the essentials
          </Typography>
          <TextField
            fullWidth
            variant="filled"
            onChange={handleInput("email")}
            label="E-Mail"
            value={state.email}
            error={errors.email?.length > 0}
          />
          {errors.email && (
            <Typography variant="body1">{errors.email}</Typography>
          )}
          <TextField
            fullWidth
            variant="filled"
            onChange={handleInput("username")}
            label="User Name"
            value={state.username}
            error={errors.username?.length > 0}
          />
          {errors.username && (
            <Typography variant="body1">{errors.username}</Typography>
          )}
          <TextField
            variant="filled"
            type={showPassword ? "text" : "password"}
            onChange={handleInput("password")}
            label="Password"
            value={state.password}
            error={errors.password?.length > 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.password && (
            <Typography variant="body1">{errors.password}</Typography>
          )}
          <TextField
            variant="filled"
            type={showConfirmPassword ? "text" : "password"}
            onChange={handleInput("confirmPassword")}
            label="Confirm Password"
            value={state.confirmPassword}
            error={errors.confirmPassword?.length > 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.passwordConfirmation && (
            <Typography variant="body1">
              {errors.passwordConfirmation}
            </Typography>
          )}
          {/* <TextField
          fullWidth
          variant="filled"
          onChange={handleInput("coupon")}
          label="Coupon"
          value={state.coupon}
          error={errors.coupon?.length > 0}
        />
        {errors.coupon && (
          <Typography variant="body1">{errors.coupon}</Typography>
        )} */}
          <div className="py-4">
            <div className="flex items-center justify-start gap-1">
              <Checkbox checked={state.terms} onChange={handleTermsCheck} />
              <Typography variant="body1">
                By submitting this form, you certify that you are 18 years of
                age or older, and agree to the{" "}
                <Link href="/terms">Terms & Conditions</Link>
              </Typography>
            </div>
            {errors.terms && (
              <Typography variant="body1">{errors.terms}</Typography>
            )}
          </div>
          <Button variant="bassnectar" onClick={handleSubmit}>
            {migratingFromEmailCTA
              ? "Update Your Account"
              : "Create Your Account"}
          </Button>
        </div>
      </div>
    </div>
  );
}
