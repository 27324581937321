import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AlbumSession,
  useActivateAlbumSessionMutationGenerated,
  useGetAlbumSuspenseQueryGenerated,
  useVerifyAlbumSessionLazyQueryGenerated,
} from "../types/generated";
import { Gradient } from "../ui/Gradient";
import AlbumCard from "./AlbumCard";
import { MediaPlayerContext } from "../Media/MediaPlayerProvider";
import { SubscriptionModal } from "../Sales/SubscriptionModal";
import { AuthContext } from "../Auth/AuthProvider";
import {
  userCanPlayAlbum,
  userCanPlayTrack,
  userGetsFreeContent,
  userIsUnlocked,
} from "../Auth/utils";
import { TrackItem } from "./TrackItem";
import MediaQuery from "react-responsive";
import { FiftyFifty } from "../ui/FiftyFifty";
import { HTMLBlock } from "../ui/components/HTMLBlock";
import { ToastContainer, toast } from "react-toastify";
import { Typography } from "@mui/material";
import LoadingIcons from "react-loading-icons";
import { formatMilliseconds } from "../utils/formatSecondsInMinutes";
import Key from "../assets/key.svg";
import { ConfirmKeyModal } from "./ConfirmKeyModal";

export function ListenDetails() {
  const { contentId } = useParams();
  const { data } = useGetAlbumSuspenseQueryGenerated({
    variables: { albumId: parseInt(contentId!) },
  });

  const [modalCondition, setModalCondition] = useState<string | undefined>(
    undefined
  );

  const [albumSession, setAlbumSession] = useState<AlbumSession | undefined>(
    undefined
  );

  const [verifyAlbumSession, { loading: sessionLoading }] =
    useVerifyAlbumSessionLazyQueryGenerated({
      variables: {
        albumId: data.getAlbum.id ?? -1,
      },
    });

  const [keyModal, setKeyModal] = useState<number | undefined>(undefined);

  const [activateAlbumSession] = useActivateAlbumSessionMutationGenerated();

  const {
    updatePlayer,
    nowPlayingAlbum,
    nowPlayingIndex,
    isPlaying,
    isBuffering,
    started,
    playbackError,
  } = useContext(MediaPlayerContext);

  const { loggedIn, user } = useContext(AuthContext);

  useEffect(() => {
    const checkSession = async () => {
      if (data?.getAlbum?.keyPrice && !userGetsFreeContent(user)) {
        const { data } = await verifyAlbumSession();
        if (data?.verifyAlbumSession) {
          setAlbumSession(data.verifyAlbumSession);
        }
      }
    };
    checkSession();
  }, [data, user, verifyAlbumSession]);

  const handlePlayAll = () => {
    handlePlayTrack(0)();
  };

  const handlePaidAlbum = async (track: number) => {
    if (albumSession) {
      if (data?.getAlbum?.category) {
        updatePlayer(data?.getAlbum.category, track, data?.getAlbum, true);
      }
    } else {
      setKeyModal(track);
    }
  };

  const handlePlayTrack = (track: number) => () => {
    const isPaid = userIsUnlocked(user);
    const isLocked = data?.getAlbum.trackList[track].locked;
    if (!loggedIn) {
      setModalCondition("guest");
    } else if (isLocked && !isPaid) {
      setModalCondition("locked");
    } else if (data.getAlbum.keyPrice) {
      handlePaidAlbum(track);
    } else {
      if (data?.getAlbum?.category) {
        updatePlayer(data?.getAlbum.category, track, data?.getAlbum, true);
      }
    }
  };

  const albumLocked = !userCanPlayAlbum(data?.getAlbum, user ?? undefined);

  const handleClose = () => {
    setModalCondition(undefined);
  };

  const handleCloseKeyModal = () => {
    setKeyModal(undefined);
  };

  const [isMixcloud, setIsMixcloud] = useState(false);

  useEffect(() => {
    if (
      data?.getAlbum?.category &&
      data?.getAlbum?.trackList &&
      data?.getAlbum?.trackList.length > 0
    ) {
      const url = data.getAlbum.trackList[0]?.embedUrl;

      if ((url?.toLowerCase()?.indexOf("mixcloud.com") ?? -1) > -1) {
        setIsMixcloud(true);
        if (!isPlaying) {
          handlePlayAll();
        }
      }
    }
  }, [data]);

  const confirmKeySpend = async (track?: number) => {
    if (track == undefined) return;
    try {
      const response = await activateAlbumSession({
        variables: {
          albumId: data?.getAlbum.id ?? -1,
        },
      });

      if (response.data?.activateAlbumSession) {
        setAlbumSession(response.data.activateAlbumSession);
        if (data?.getAlbum?.category) {
          updatePlayer(data?.getAlbum.category, track, data?.getAlbum, true);
        }
      }
    } catch (e: unknown) {
      toast.error((e as Error).message);
    }
  };

  return (
    <>
      {data?.getAlbum && (
        <div id={data.getAlbum.cssId ? data.getAlbum.cssId : "album-detail" }>
          {data.getAlbum.gradient && (
            <Gradient gradient={data.getAlbum.gradient} />
          )}
          {data.getAlbum.html && (
            <HTMLBlock markup={data.getAlbum.html.markup} />
          )}
          <SubscriptionModal
            open={modalCondition !== undefined}
            reason={modalCondition}
            onDismiss={handleClose}
          />
          <ConfirmKeyModal
            open={keyModal != undefined && data.getAlbum.keyPrice != undefined}
            price={data.getAlbum.keyPrice}
            track={keyModal}
            onDismiss={handleCloseKeyModal}
            onConfirm={confirmKeySpend}
          />
          <MediaQuery minWidth={1024}>
            <div className="relative z-10 flex w-screen flex-col items-center">
              <div className="w-full max-w-[1080px] px-4">
                <FiftyFifty>
                  <div className="flex flex-col items-start">
                    <AlbumCard
                      key={data.getAlbum.id}
                      id={data.getAlbum.id}
                      variant="large"
                      title={data.getAlbum.title ?? ""}
                      artwork={data.getAlbum.coverArt ?? undefined}
                      info={data.getAlbum.artist ?? undefined}
                      playAllButton={!isMixcloud}
                      playAllAction={handlePlayAll}
                      locked={albumLocked}
                    />
                    {sessionLoading && !albumSession && (
                      <LoadingIcons.Bars width="32px" height="32px" />
                    )}
                    {!sessionLoading && albumSession && (
                      <div>{`Session: ${formatMilliseconds(
                        14400000 - (albumSession.elapsedTime ?? 0)
                      )}`}</div>
                    )}
                    {!sessionLoading &&
                      !albumSession &&
                      data.getAlbum.keyPrice &&
                      !userGetsFreeContent(user) && (
                        <div className="flex items-center justify-center rounded-xl bg-black px-2 py-1 text-white">
                          <Typography variant="h5">
                            {data.getAlbum.keyPrice}
                          </Typography>
                          <img
                            alt="key-icon"
                            src={Key}
                            className="ml-2 h-5 w-5 text-white"
                            style={{ fill: "#ffffff" }}
                          />
                        </div>
                      )}
                  </div>

                  <>
                    {data.getAlbum.trackList.map((song, i) => (
                      <TrackItem
                        key={`song-${data.getAlbum.id}-${i}`}
                        title={song.title ?? ""}
                        info={song.artist ?? data.getAlbum.artist ?? ""}
                        unlocked={userCanPlayTrack(song, user ?? undefined)}
                        onPlay={handlePlayTrack(i)}
                        active={
                          nowPlayingAlbum.id === data.getAlbum.id &&
                          nowPlayingIndex === i
                        }
                        isPlaying={isPlaying && started && !isBuffering}
                        isError={playbackError}
                        started={started}
                      />
                    ))}
                  </>
                </FiftyFifty>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={1023}>
            <div className="z-2 relative flex flex-col items-start space-y-1 px-4">
              <AlbumCard
                key={data.getAlbum.id}
                id={data.getAlbum.id}
                variant="large"
                title={data.getAlbum.title ?? ""}
                artwork={data.getAlbum.coverArt ?? undefined}
                info={data.getAlbum.artist ?? undefined}
                playAllButton={!isMixcloud}
                playAllAction={handlePlayAll}
                locked={albumLocked}
              />
              {sessionLoading && !albumSession && (
                <LoadingIcons.Bars width="32px" height="32px" />
              )}
              {!sessionLoading && albumSession && (
                <div>{`Session: ${formatMilliseconds(
                  14400000 - (albumSession.elapsedTime ?? 0)
                )}`}</div>
              )}
              {!sessionLoading &&
               !albumSession &&
               data.getAlbum.keyPrice &&
               !userGetsFreeContent(user) && (
                <div className="flex items-center justify-center rounded-xl bg-black px-2 py-1 text-white">
                  <Typography variant="h5">{data.getAlbum.keyPrice}</Typography>
                  <img
                    alt="key-icon"
                    src={Key}
                    className="ml-2 h-5 w-5 text-white"
                    style={{ fill: "#ffffff" }}
                  />
                </div>
              )}
              <div className="mb-4 flex w-full flex-col gap-4">
                {data.getAlbum.trackList.map((song, i) => (
                  <TrackItem
                    key={`song-${data.getAlbum.id}-${i}`}
                    title={song.title ?? ""}
                    info={song.artist ?? data.getAlbum.artist ?? ""}
                    unlocked={userCanPlayTrack(song, user ?? undefined)}
                    onPlay={handlePlayTrack(i)}
                    active={
                      nowPlayingAlbum.id === data.getAlbum.id &&
                      nowPlayingIndex === i
                    }
                    isPlaying={isPlaying && started && !isBuffering}
                    isError={playbackError}
                    started={started}
                  />
                ))}
              </div>
            </div>
          </MediaQuery>
          <ToastContainer />
        </div>
      )}
    </>
  );
}
