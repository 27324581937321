import env from "react-dotenv";
import { ReactNode, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Badge, Button, Typography } from "@mui/material";
import { SectionHero } from "../ui/components/SectionHero";
import { TreasureBox } from "./TreasureBox";
import { InfoFooter } from "../ui/InfoFooter";
import { AuthContext } from "../Auth/AuthProvider";
import { Divider } from "../ui/Divider";
import Unlocked from "../assets/unlocked.png";
import NotificationsIcon from "../assets/notifications.svg";
import DefaultProfilePicture from "../assets/defaultProfilePicture.svg";
import AccountInformationIcon from "../assets/accountInformation.svg";
import KeyIcon from "../assets/KeyIcon.svg";
import ArcadeIcon from "../assets/arcadeIcon.svg";
import ContactSupportIcon from "../assets/contactSupport.svg";
import { NotificationsContext } from "./NotificationsContext";
import { userIsUnlocked, userIsGold, userHasLinkedDiscord } from "../Auth/utils";
import { CouponCodeModal } from "./CouponCodeModal";
import { AppVersion } from "../ui/AppVersion";
import { PurchaseKeysModal } from "./PurchaseKeysModal";
import { UpgradeSubscriptionModal } from "./UpgradeSubscriptionModal";
import { ProfileListSection } from "./ProfileListSection";
import DiscordIcon from "../assets/DiscordIcon.svg";
import MediaQuery from "react-responsive";

export interface Section {
  icon: string;
  title: string;
  link?: {
    url: string;
    rel?: string;
    target?: string;
  };
  action?: ReactNode;
  badge?: ReactNode;
}

const Settings = () => {
  const navigate = useNavigate();

  const { user, signOut, getIdToken, waitForUserAttribute } = useContext(AuthContext);
  const { unreadCount } = useContext(NotificationsContext);

  const [searchParams] = useSearchParams();

  const logout = async () => {
    await signOut();
    navigate("/");
  };

  const [sections, setSections] = useState<Section[]>([]);

  const [idToken, setIdToken] = useState<string>();

  const [showUpgradeSubscription, setShowUpgradeSubscription] = useState(false);

  useEffect(() => {
    const refreshUser = async () => {
      await waitForUserAttribute("discordId");
      navigate("/profile");
    }
    const discordLinked = searchParams.get("discordLinked");
    if(discordLinked) {
      refreshUser();
    }
  }, [searchParams, waitForUserAttribute, navigate]);

  useEffect(() => {
    const buildSections = (groups: string[]) => {
      const sections: Section[] = [
        {
          icon: NotificationsIcon,
          title: "Notifications",
          link: {
            url: "/profile/notifications",
          },
          badge: <Badge badgeContent={unreadCount} color="error" />,
        },
        {
          icon: DefaultProfilePicture,
          title: "Profile Picture",
          link: {
            url: "/profile/avatar",
          },
        },
        {
          icon: AccountInformationIcon,
          title: "My Account Information",
          link: {
            url: "/profile/accountInfo",
          },
        },
        {
          icon: ArcadeIcon,
          title: "My High Scores",
          link: {
            url: "/profile/scores",
          },
        },
        {
          icon: ContactSupportIcon,
          title: "Get help",
          link: {
            url: "https://bassnetwork.freshdesk.com/support/tickets/new",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      ];

      if (userIsUnlocked(user)) {
        sections.push({
          icon: KeyIcon,
          title: "Keys",
          action: (
            <div className="flex items-center justify-center space-x-2">
              <Button
                className="w-full"
                variant="bassnectar"
                onClick={() => setPurchaseKeys(50)}
              >
                Add Keys
              </Button>
            </div>
          ),
        });

        if (userIsGold(user) === false) {
          sections.push({
            icon: AccountInformationIcon,
            title: "Upgrade Subscription",
            action: (
              <Button
                variant="bassnectar"
                onClick={() => setShowUpgradeSubscription(true)}
              >
                UPGRADE
              </Button>
            ),
          });
        }

        if (userIsUnlocked(user)) {
          sections.push({
            icon: DiscordIcon,
            title: "Community Haven",
            action: (
              <div className="flex items-center justify-center space-x-2">
                {userHasLinkedDiscord(user) === false ? (
                  <a
                    href={`https://discord.com/oauth2/authorize?client_id=1238256833102348419&response_type=code&redirect_uri=${encodeURIComponent(
                      `${env.API_URL}/join-discord`
                    )}&scope=identify+guilds.join&state=${idToken}`}
                  >
                    <Button className="w-full" variant="bassnectar">
                      <span>CONNECT</span>
                      <MediaQuery minWidth={769}>
                         <span className="ml-1">DISCORD</span>
                      </MediaQuery>
                    </Button>
                  </a>
                ) : (
                  <a
                    href="https://discord.com/channels/784687598000930847/1065052871416090625"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="w-full" variant="bassnectar">
                      OPEN
                    </Button>
                  </a>
                )}
              </div>
            ),
          });
        }
      }

      setSections(sections);
    };

    buildSections(user?.groups ?? []);
  }, [user, user?.groups, unreadCount, idToken]);

  const [showCoupon, setShowCoupon] = useState(false);
  const [purchaseKeys, setPurchaseKeys] = useState<number>();

  useEffect(() => {
    const loadToken = async () => {
      setIdToken(await getIdToken());
    };

    loadToken();
  }, [getIdToken]);

  return (
    <div id="profile-detail">
      {user && (
        <>
          <SectionHero title="Profile" />
          <CouponCodeModal
            open={showCoupon}
            onDismiss={() => setShowCoupon(false)}
          />
          <PurchaseKeysModal
            open={purchaseKeys !== undefined}
            quantity={purchaseKeys ?? 0}
            onDismiss={() => setPurchaseKeys(undefined)}
          />
          <UpgradeSubscriptionModal
            open={showUpgradeSubscription}
            onDismiss={() => setShowUpgradeSubscription(false)}
          />
          <div className="flex flex-col lg:min-w-[100vw] lg:items-center">
            <div className="flex flex-col items-center justify-center px-6 lg:max-w-[750px]">
              <div className="my-4 flex w-full items-center justify-between px-2">
                <Typography variant="h5">{`Welcome ${
                  user!.username
                }!`}</Typography>
              </div>

              <div className="w-full">
                <Divider />
              </div>

              {user && userIsUnlocked(user) && !userIsGold(user) && (
                <>
                  <div className="mb-[20px] mt-6 flex items-center gap-4">
                    <img src={Unlocked} alt="unlocked icon" />
                    <MediaQuery maxWidth={1023}>
                      <div className="flex flex-col text-center ">
                        <Typography variant="subheading">
                          You've Unlocked
                        </Typography>
                        <Typography variant="subheading">
                          The Other Side!
                        </Typography>
                      </div>
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                      <Typography variant="subheading">You've Unlocked The Other Side!</Typography>
                    </MediaQuery>
                  </div>
                </>
              )}

              {user && userIsUnlocked(user) && userIsGold(user) && (
                <>
                  <div className="w-full mb-[20px] mt-6 px-4 flex items-center">
                    <img src={Unlocked} alt="unlocked icon" className="max-h-24 mr-4" />
                    <MediaQuery maxWidth={1023}>
                      <div className="flex flex-col text-center">
                        <Typography variant="gold">
                          You've Unlocked
                        </Typography>
                        <Typography variant="gold">
                          TOS Gold
                        </Typography>
                      </div>
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                      <Typography variant="gold">You've Unlocked TOS Gold!</Typography>
                    </MediaQuery>
                  </div>
                </>
              )}

              <div className="w-full">
                <Divider />
              </div>

              {sections.map((section, index) => (
                <ProfileListSection key={index} section={section} />
              ))}

              <div className="w-full">
                {userIsUnlocked(user) ? (
                  <div className="mt-6">
                    <TreasureBox />
                  </div>
                ) : (
                  <div className="tos-body-bkg relative mb-2 text-center">
                    <div className="absolute left-0 top-0 h-full w-full rounded-xl bg-white mix-blend-overlay"></div>
                    <div className="mt-10 px-2 py-6">
                      <Link to="/subscribe">
                        <Button variant="bassnectar">
                          Upgrade to Unlocked - $10/month
                        </Button>
                      </Link>
                    </div>
                    <div className="relative flex flex-col gap-4 px-2 pb-6">
                      <Typography variant="body1">
                        With a paid subscription you gain access to all current
                        and future content and plenty of members-only perks!
                      </Typography>
                      <Link to="/about">
                        <Button variant="bassnectarText">
                          Explore UNLOCKED Features
                        </Button>
                      </Link>
                    </div>
                  </div>
                )}

                <div className="mt-8 flex w-full justify-center">
                  <Button variant="bassnectarJumbo" onClick={logout} fullWidth>
                    Sign Out
                  </Button>
                </div>

                <div className="mt-4 flex w-full flex-nowrap items-center justify-center text-center">
                  <AppVersion />
                </div>

                <div className="mt-4 flex justify-center">
                  <Button
                    variant="bassnectar"
                    onClick={() => setShowCoupon(true)}
                  >
                    Apply Code
                  </Button>
                </div>

                <InfoFooter />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
